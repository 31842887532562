<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label='标签名称'>
            <el-input v-model.trim="queryForm.usernameLike" clearable placeholder="请输入用户名" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleEdit($event)">
              查询
            </el-button>
            <el-button type="primary" @click="handleEdit($event)">
              添加标签
            </el-button>
          </el-form-item>

        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" fixed label="标签" prop="username" show-overflow-tooltip />
      <el-table-column align="center" label="备注" prop="realName" show-overflow-tooltip />
      <el-table-column align="center" label="持有人数" prop="phone" show-overflow-tooltip />
      <el-table-column align="center" fixed="right" label="操作" show-overflow-tooltip>
        <template #default="{ row }">
          <el-button @click="handleEdit(row)" type="text">导入</el-button>
          <el-button @click="handleEdit(row)" type="text">编辑</el-button>
          <el-button @click="handleEdit(row)" type="danger">删除</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="queryForm.page" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <edit ref="editRef" @fetch-data="fetchData" />
  </div>
</template>

<script>
import {
  defineAsyncComponent,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import { deleteUser, getList, distributionShop } from '@/api/userManagement'
import { getProvinceInfo, getCityInfo, getAreaInfo } from '@/api/shop'
import { countryQueryInfo } from '@/api/brand'
export default defineComponent({
  name: 'UserManage',
  components: {
    Edit: defineAsyncComponent(() => import('./components/userDetail.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      shortcuts: [
        {
          text: 'Last week',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setDate(start.getDate() - 7)
            return [start, end]
          },
        },
        {
          text: 'Last month',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 1)
            return [start, end]
          },
        },
        {
          text: 'Last 3 months',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 3)
            return [start, end]
          },
        },
      ],
      labelPosition: `left`,
      editRef: null,
      list: [],
      listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      selectRows: '',
      showShop: false,
      counrtyList: [],
      queryForm: {
        page: 1,
        pageSize: 10,
        usernameLike: '',
        userType: 'All',
        officalCount: 'All',
        userSource: 'All',
      },
      userTypeList: [
        {
          name: '全部',
          key: 'All',
        },
        {
          name: '未注册',
          key: 'noReister',
        },
        {
          name: '已注册未实名',
          key: 'registerNoreal',
        },
        {
          name: '已注册已实名',
          key: 'register',
        },
      ],
      officalCountList: [
        {
          name: '全部',
          key: 'All',
        },
        {
          name: '已关注',
          key: 'follow',
        },
        {
          name: '未关注',
          key: 'unfollow',
        },
      ],
      userSourceList: [
        {
          name: '全部',
          key: 'All',
        },
        {
          name: '扫企业码',
          key: '1',
        },
        {
          name: '扫展会码',
          key: '2',
        },
        {
          name: '展会报名',
          key: '3',
        },
        {
          name: '业务咨询-系统留言',
          key: '4',
        },
        {
          name: '业务咨询-自定义留言',
          key: '5',
        },
        {
          name: '关注展会',
          key: '6',
        },
        {
          name: '收藏展会',
          key: '7',
        },
        {
          name: '收藏产品',
          key: '8',
        },
        {
          name: '收藏机构',
          key: '9',
        },
        {
          name: '产品咨询',
          key: '10',
        },
        {
          name: 'AI助理',
          key: '11',
        },
        {
          name: '展品-联系我们',
          key: '12',
        },
        {
          name: '展商-联系我们',
          key: '13',
        },
        {
          name: 'WeMeet首页Banner',
          key: '14',
        },
        {
          name: '联系人互动',
          key: '15',
        },
        {
          name: '批量导入',
          key: '16',
        },
        {
          name: '同步公众号粉丝',
          key: '17',
        },
      ],
    })

    const setSelectRows = (val) => {
      state.selectRows = val
    }
    const handleEdit = (row) => {
      console.log(row)
      if (row.uid) {
        state['editRef'].handleOpen(row)
      } else {
        state['editRef'].handleOpen()
      }
    }
    const handleDelete = (row) => {
      if (row.uid) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await deleteUser(row.uid)
          proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }
    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      fetchData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.page = val
      fetchData()
    }
    const queryData = () => {
      state.queryForm.page = 1
      fetchData()
    }
    const fetchData = async () => {
      state.listLoading = true
      const { data } = await getList(state.queryForm)
      state.list = data.list
      state.total = data.total
      state.listLoading = false

      // 查询所有国家列表
      const { data: country } = await countryQueryInfo()
      console.log(country)
      state.counrtyList = country
    }
    onMounted(() => {
      fetchData()
    })

    const selectChange = (type, key) => {
      if (type == 'userType') {
        state.queryForm.userType = key
      }
      if (type == 'officalCount') {
        state.queryForm.officalCount = key
      }
      if (type == 'userSource') {
        state.queryForm.userSource = key
      }
    }

    const optionsLevel = {
      lazy: true,
      value: 'name',
      label: 'name',
      async lazyLoad(node, resolve) {
        const { level } = node
        const { code } = node.data
        if (level == 0) {
          const { data } = await getProvinceInfo()
          resolve(data)
        } else if (level == 1) {
          const { data } = await getCityInfo({ code: code })
          resolve(data)
        } else {
          const { data } = await getAreaInfo({ code: code })
          data.forEach((v, i) => {
            v.leaf = level >= 2
          })
          resolve(data)
        }
      },
    }

    return {
      ...toRefs(state),
      setSelectRows,
      handleEdit,
      handleDelete,
      handleSizeChange,
      handleCurrentChange,
      queryData,
      fetchData,
      selectChange,
      optionsLevel,
    }
  },
})
</script>


<style lang="scss" scoped>
.radioBox {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-wrap: wrap;
  li {
    list-style: none;
    margin-right: 20px;
    cursor: pointer;
    &.isChecked {
      color: #00c4c9;
    }
  }
}
</style>